<template>
    <div>
        <nav-admin></nav-admin>
        <div class="container pt-4">
            <div class="row">
                <div class="col-2">
                    <h4 class="h4">General</h4>
                    <ul>
                        <li v-if="$auth.check(admin.id)"><router-link :to="{ path: '/admin/users' }">Users</router-link></li>
                        <li v-if="$auth.check(admin.id)"><router-link :to="{ path: '/admin/settings' }">Settings</router-link></li>
                    </ul>
                </div>
                <div v-if="$auth.check(admin.id)" class="col-2">
                    <h4 class="h4">Content</h4>
                    <ul>
                        <li><router-link :to="{ path: '/admin/photos' }">Photos</router-link></li>
                        <li><router-link :to="{ path: '/admin/videos' }">Videos</router-link></li>
                        <li><router-link :to="{ path: '/admin/faq' }">FAQ</router-link></li>
                    </ul>
                </div>
                <div v-if="$auth.check(admin.id)" class="col-2">
                    <h4 class="h4">Marketing</h4>
                    <ul>
                        <li v-if="$auth.check(admin.id) || $auth.check(editor.id)"><router-link :to="{ path: '/admin/orders' }">Orders</router-link></li>
                        <li v-if="$auth.check(admin.id) || $auth.check(editor.id)"><router-link :to="{ path: '/admin/discount' }">Promo codes</router-link></li>
                        <li v-if="$auth.check(admin.id)"><router-link :to="{ path: '/admin/marketing/events' }">Campaigns</router-link></li>
                        <li><router-link :to="{ path: '/admin/packages' }">Packages</router-link></li>
                        <li v-if="$auth.check(admin.id) || $auth.check(editor.id)"><router-link :to="{ path: '/admin/customers' }">Customers</router-link></li>
                        <li><router-link :to="{ path: '/admin/emails' }">Emails</router-link></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import navAdmin from './../components/nav-admin';
import { userType } from './../mixins/user-type.mixin';

export default {
    mixins: [userType],
    components: {
        navAdmin
    }
}
</script>